// the ~ allows you to reference things in node_modules
@import "~bootstrap/scss/bootstrap";
@import "~bootstrap-table/dist/bootstrap-table.css";
@import "~tempusdominus-bootstrap-4/src/sass/_tempusdominus-bootstrap-4.scss";
@import "~fullcalendar/dist/fullcalendar.css";
@import "~qtip2/dist/jquery.qtip.css";
@import "~summernote/dist/summernote.css";

.mimifont {
    font-family: Arial, arial, "URW Chancery L Regular", "URW Chancery L", "Magorian Regular", Georgia, Times, "Times New Roman", serif;
}

html, body{
  padding: 0;
  margin: 0;
}
html{
  height: 100%;
}
body{
  min-height: 100vh;
}

.bg-lightdark{
    background-color: #2d3135;
}

.admincontent{min-height: 100vh;}

.adminarea{
    font-family: 'Roboto Mono',monospace;
    background-color: #82c0ff;
}

.adminarea > div > div > ul > li > a {
    color: #82bffc;
}

.adminbox{
    background-color: #81889d;
    border: 1px solid #eee;
    box-shadow: 0 0 10px rgba(6,6,6,.55);
}
.lob{
    border: 1px solid #212529;
    box-shadow: 0 0 10px rgba(255,255,255,.51);
}

#navbar{
    border-bottom: 1px solid #FFF;
    z-index: 9999;
}

.last-nav {
    padding-right: 1.0rem;
}

.content {
  padding-top: 5px;
  min-height: 100vh;
}

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9;
  background-color: #616e95 !important;
}

.sticky + .content {
  padding-top: 60px;
}

.alert{
    z-index:99999;
}

.bg-blue {
    background-color: #293559;
}

.carousel-image{
    border: 1px solid #FFF;
}

.fronttext {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 2em;
    background-color: #00000030;
    height:100%;
    width:100%;
    max-width:1249px;
}

.centertext{
    position: relative;
    float: left;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}

.box{
    padding: 1em;
}

.bg {
  min-height: 100vh;
  background-image: url("images/bg-castle.jpg");
  background-color: white;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.horizontal-line{
    background-color: #293559;
    margin-top: 1em;
    margin-bottom: 1em;
    height: 3px;
}

.bold{
    font-weight: bold;
}

.italic{
    font-style: italic;
}

.guidetext{
    background-color: #ffffffcc;
    border: 1px black;
    margin: 1em;
    padding: 1em;
    border-style: groove;
    border-color: black;
    border-width: 1px;
    font-size: 1.5em;
    min-height: 15em;
}

.small-circle{
    max-width: 10rem;
    max-height: 10rem;
}

.center-btn{
    margin-top: 1em;
    padding-left: 25%;
    padding-right: 25%;
}

.tourlink{
    color: black;
}

.small-img{
    max-height: 250px;
}

.btn-primary{
    background-color: #576db6;
    border-color: #FFFFFF;
}
.btn-primary:hover, .btn-primary:focus, .btn-primary:active, .btn-primary.active, .open>.dropdown-toggle.btn-primary {
    color: #fff;
    background-color: #6881d4;
    border-color: #FFFFFF;
}

.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle {
    color: #000000;
    background-color: #e5f2ff;
    border-color: #a3bfdc;
}

.btn-primary.disabled, .btn-primary:disabled {
    color: #000000;
    background-color: #e5f2ff;
    border-color: #a3bfdc;
}

.btn-success{
    color: #19295f !important;
    border-color: #FFFFFF;
}

.transparent{
    background-color: #FFF0;
}

.alert-info{
    background-color: #e9ecef87;
}

.y-button {
    max-height: 2em;
}

.vertical-center {
    min-height: 50%;
    min-height: 50vh;

    display: flex;
    align-items: center;
}

.table-wrapper-scroll-y {
    display: block;
    max-height: 500px;
    overflow-y: auto;
    -ms-overflow-style: -ms-autohiding-scrollbar;
}

.legend-public {
    background-color: #28a745bb;
}
.legend-private {
    background-color: #576db6bb;
}
.legend-blocker {
    background-color: #232323bb;
}

.page-link {
   color: #576db6;
}

.page-item.active .page-link {
   color: white;
   background-color: #576db6;
}

.nav-button{
    background-color: #576db6;
}


.content-title{
    color: white;
    display: inline;
    text-decoration: underline;
}

.fc-center h2{
    font-size: 1.25em;
    padding: 1px;
}

.new-entry{
    margin-top: 2em;
}


